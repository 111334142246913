import { BoxStyleProps } from "@twilio-paste/core/box";
import { TextStyleProps } from "@twilio-paste/core/text";

export const channelSelectStyles: BoxStyleProps = {
    padding: "space40",
    paddingTop: "space80",
    overflow: "auto"
};

export const channelTitleStyles: TextStyleProps = {
    fontSize: "fontSize70",
    marginBottom: "space30"
};

export const channelChatHeading: BoxStyleProps = {
    marginLeft: "space30"
};

export const channelSelectElementStyles: BoxStyleProps = {
    padding: "space40",
    paddingTop: "space80",
    overflow: "auto",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexWrap: "wrap",
    borderRadius: "borderRadius30",
    borderStyle: "solid",
    borderWidth: "borderWidth20",
    margin: "space40",
    borderColor: "colorBorderPrimaryWeak",
    backgroundColor: "colorBackgroundDecorative20Weakest"
};

export const channelSelectElementTextStyles: BoxStyleProps = {
    textAlign: "center",
    width: "100%"
};

export const channelSelectElementButtonStyles: BoxStyleProps = {
    marginTop: "space60",
    marginBottom: "space40"
};

export const channelSelectElementTitleStyles: BoxStyleProps = {
    textAlign: "center",
    width: "100%",
    fontSize: "fontSize50",
    fontWeight: "fontWeightBold",
    paddingBottom: "space40"
};
