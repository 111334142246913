import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { Box } from "@twilio-paste/core/box";
import { TextArea } from "@twilio-paste/core/textarea";
import { Combobox } from "@twilio-paste/core/combobox";
import React, { FormEvent } from "react";
import { Button } from "@twilio-paste/core/button";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "@twilio-paste/core/text";

import { sessionDataHandler } from "../sessionDataHandler";
import { addNotification, changeEngagementPhase, updatePreEngagementData } from "../store/actions/genericActions";
import { initSession } from "../store/actions/initActions";
import { AppState, EngagementPhase } from "../store/definitions";
import { Header } from "./Header";
import { notifications } from "../notifications";
import { NotificationBar } from "./NotificationBar";
import { fieldStyles, formStyles, introStyles, titleStyles } from "./styles/PreEngagementFormPhase.styles";

const VenueCombobox = () => {
    const { venue, existing } = useSelector((state: AppState) => state.session.preEngagementData) || {};
    const { venues } = useSelector((state: AppState) => state.session) || {};
    const dispatch = useDispatch();

    const groupedItems: { id: string; label: string; region: string }[] = [];
    let selectedVenue = null;
    if (venues !== null && venues !== undefined) {
        const enCollator = new Intl.Collator("en");

        venues
            .sort((a, b) =>
                enCollator.compare(
                    `${a.addressRegionSequence}_${a.addressRegionName}_${a.venueName}`,
                    `${b.addressRegionSequence}_${b.addressRegionName}_${b.venueName}`
                )
            )
            .forEach((venueItem) => {
                groupedItems.push({
                    id: venueItem.id,
                    label: venueItem.venueName,
                    region: venueItem.addressRegionName
                });

                if (venueItem.id === venue) {
                    selectedVenue = venueItem.venueName;
                }
            });
    }

    return (
        <Combobox
            autocomplete
            groupItemsBy="region"
            items={groupedItems}
            initialSelectedItem={selectedVenue}
            labelText="Select your venue"
            name="venue"
            optionTemplate={(item) => <div>{item.label}</div>}
            itemToString={(item) => (item ? item.label : null)}
            onSelectedItemChange={({ inputValue }) => {
                groupedItems.forEach((venueItem) => {
                    if (venueItem.label === inputValue) {
                        dispatch(updatePreEngagementData({ venue: venueItem.id }));
                    }
                });
            }}
            required={existing === null ? false : existing}
        />
    );
};

export const PreEngagementFormPhase = () => {
    const { name, email, query, existing, venue } =
        useSelector((state: AppState) => state.session.preEngagementData) || {};
    const dispatch = useDispatch();

    const existingOptions = ["I am new to Swimming Nature", "I currently have lessons"];

    let selectedExistingElement = null;
    if (existing !== null && !existing) {
        selectedExistingElement = existingOptions[0];
    } else if (existing !== null && existing) {
        selectedExistingElement = existingOptions[1];
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
        try {
            const data = await sessionDataHandler.fetchAndStoreNewSession({
                formData: {
                    friendlyName: name,
                    email,
                    query,
                    existing,
                    venueId: venue
                }
            });
            dispatch(initSession({ token: data.token, conversationSid: data.conversationSid }));
        } catch (err) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    return (
        <>
            <Header />
            <NotificationBar />
            <Box as="form" data-test="pre-engagement-chat-form" onSubmit={handleSubmit} {...formStyles}>
                <Text {...titleStyles} as="h3">
                    Hi there!
                </Text>
                <Text {...introStyles} as="p">
                    We&#39;re here to help. Please give us some info to get started.
                </Text>
                <Box {...fieldStyles}>
                    <Label htmlFor="name">Name</Label>
                    <Input
                        type="text"
                        placeholder="Please enter your name"
                        name="name"
                        data-test="pre-engagement-chat-form-name-input"
                        value={name}
                        onChange={(e) => dispatch(updatePreEngagementData({ name: e.target.value }))}
                        required
                    />
                </Box>
                <Box {...fieldStyles}>
                    <Label htmlFor="email">Email address</Label>
                    <Input
                        type="email"
                        placeholder="Please enter your email address"
                        name="email"
                        data-test="pre-engagement-chat-form-email-input"
                        value={email}
                        onChange={(e) => dispatch(updatePreEngagementData({ email: e.target.value }))}
                        required
                    />
                </Box>

                <Box {...fieldStyles}>
                    <Combobox
                        items={existingOptions}
                        initialSelectedItem={selectedExistingElement}
                        labelText="Do you currently have lessons with us?"
                        name="existing"
                        data-test="pre-engagement-chat-form-existing-checkbox"
                        onSelectedItemChange={(e) =>
                            dispatch(updatePreEngagementData({ existing: e.inputValue === existingOptions[1] }))
                        }
                        required
                    />
                </Box>

                <Box {...fieldStyles}>
                    <VenueCombobox />
                </Box>

                <Box {...fieldStyles}>
                    <Label htmlFor="query">How can we help you?</Label>
                    <TextArea
                        placeholder="Ask a question"
                        name="query"
                        data-test="pre-engagement-chat-form-query-textarea"
                        value={query}
                        onChange={(e) => dispatch(updatePreEngagementData({ query: e.target.value }))}
                        onKeyPress={handleKeyPress}
                        required
                    />
                </Box>

                <Button variant="primary" type="submit" data-test="pre-engagement-start-chat-button">
                    Start chat
                </Button>
            </Box>
        </>
    );
};
