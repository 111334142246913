import { Box } from "@twilio-paste/core/box";
import {useDispatch, useSelector} from "react-redux";

import { MessagingCanvasPhase } from "./MessagingCanvasPhase";
import {AppState, EngagementPhase, VenueWrapper} from "../store/definitions";
import { PreEngagementFormPhase } from "./PreEngagementFormPhase";
import { LoadingPhase } from "./LoadingPhase";
import { EntryPoint } from "./EntryPoint";
import { innerContainerStyles, outerContainerStyles } from "./styles/RootContainer.styles";
import {contactBackend} from "../sessionDataHandler";
import {updateVenues} from "../store/actions/genericActions";
import {MessageChannelSelectPhase} from "./MessageChannelSelectPhase";

const getPhaseComponent = (phase: EngagementPhase) => {
    switch (phase) {
        case EngagementPhase.Loading:
            return <LoadingPhase />;
        case EngagementPhase.MessagingCanvas:
            return <MessagingCanvasPhase />;
        case EngagementPhase.MessageChannelSelect:
            return <MessageChannelSelectPhase />;
        case EngagementPhase.PreEngagementForm:
        default:
            return <PreEngagementFormPhase />;
    }
};

let venuesLoaded = false;

export function RootContainer() {
    const { currentPhase, expanded } = useSelector(({ session }: AppState) => ({
        currentPhase: session.currentPhase,
        expanded: session.expanded
    }));
    const dispatch = useDispatch();

    const windowHeight = window.innerHeight;
    let height = 690;
    if (height + 100 > windowHeight) {
        height = windowHeight - 100; // Removing 100 for the header
    }

    const venueCall = async () => {
        const flowUrl = process.env.REACT_APP_FLOW_API_URL as string;
        const flowVenuesUrl = process.env.REACT_APP_VENUES_URL as string;

        const response: VenueWrapper = await contactBackend(flowUrl + flowVenuesUrl, {}, "GET");

        if (response._embedded !== null && response._embedded !== undefined) {
            dispatch(updateVenues(response._embedded.venues));
        }
    };

    if (expanded && !venuesLoaded) {
        venueCall();
        venuesLoaded = true;
    }

    return (
        <Box>
            <Box {...outerContainerStyles}>
                {expanded && (
                    <Box data-test="root-container" {...innerContainerStyles} style={{ height }}>
                        {getPhaseComponent(currentPhase)}
                    </Box>
                )}
                <EntryPoint />
            </Box>
        </Box>
    );
}
