import { Client, Conversation, Participant, Message, User } from "@twilio/conversations";
import { GenericThemeShape } from "@twilio-paste/theme";
import { AlertVariants } from "@twilio-paste/alert/dist/Alert";

import { FileAttachmentConfig, TranscriptConfig } from "../definitions";

export enum EngagementPhase {
    PreEngagementForm = "PreEngagementForm",
    MessagingCanvas = "MessagingCanvas",
    Loading = "Loading",
    MessageChannelSelect = "MessageChannelSelect"
}

export type ChatState = {
    conversationsClient?: Client;
    conversation?: Conversation;
    participants?: Participant[];
    users?: User[];
    messages?: Message[];
    attachedFiles?: File[];
    conversationState?: string;
};

export type PreEngagementData = { name: string; email: string; query: string; existing: boolean | null; venue: string };

export type Venue = {
    venueCustomerServiceTelephone: string;
    venueTelephone: string;
    latitude: number;
    venueEmail: string;
    venueLocality: string;
    venueUrl: string;
    longitude: number;
    code: string;
    includeOnWebsite: boolean;
    venueName: string;
    id: string;
    placeId: string;
    autoEnrolmentEnabled: boolean;
    addressRegionName: string;
    addressRegionSequence: number;
};

// eslint-disable-next-line import/no-unused-modules
export type VenueList = {
    venues: Venue[];
};

// eslint-disable-next-line import/no-unused-modules
export type VenueWrapper = {
    _embedded?: VenueList;
};

export type SessionState = {
    currentPhase: EngagementPhase;
    expanded: boolean;
    token?: string;
    conversationSid?: string;
    conversationsClient?: Client;
    conversation?: Conversation;
    users?: User[];
    participants?: Participant[];
    messages?: Message[];
    conversationState?: "active" | "inactive" | "closed";
    preEngagementData?: PreEngagementData;
    venues?: Venue[];
};

export type ConfigState = {
    serverUrl?: string;
    theme?: {
        isLight?: boolean;
        overrides?: Partial<GenericThemeShape>;
    };
    fileAttachment?: FileAttachmentConfig;
    transcript?: TranscriptConfig;
};

export type Notification = {
    dismissible: boolean;
    id: string;
    onDismiss?: () => void;
    message: string;
    timeout?: number;
    type: AlertVariants;
};

export type NotificationState = Notification[];

export type AppState = {
    chat: ChatState;
    config: ConfigState;
    session: SessionState;
    notifications: NotificationState;
};
